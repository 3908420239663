import React from 'react'
import axios from "axios";

//https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&include_granted_scopes=true&response_type=code&client_id=231033912261-o0muea7k68lb3dfpe7cac5f4cbvftj9q.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000

export const YTLogin = async () => {
    let redirectURL;
    console.log("youtube login ")
    await axios.post("http://localhost:3001/googleLogin").then(res => {
            console.log("google login response: "+ res.data.redirectURL);
            if(res.data && res.data.redirectURL){
                redirectURL = res.data.redirectURL;
            }
        }
    ).catch((err) => {
        console.log("google login error: " + err)
    })
    return redirectURL;
}