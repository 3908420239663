import React from "react";

const Contact = () => {
    return (
        <div className="contact-page-wrapper">
            <h1 className="primary-heading">Have Question In Mind?</h1>
            <h1 className="primary-heading">Contact Me</h1>
            <div className="contact-form-container">
                <p> siwu945@gmail.com</p>
            </div>
        </div>
    );
};

export default Contact;