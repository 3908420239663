//TODO: 1. Fix all check function
import React from 'react';
import axios from "axios";


function YTDashboard({code}){

    async function yt_playlist() {
        await axios.post("http://localhost:3001/oauth2callback", {
            code,
        }).then(res =>{
            const play_list = res.data.data.items;
            for(const item of play_list){
                console.log(item.snippet.title);
            }
        })
    }

    return(
        <div>
            <button onClick={yt_playlist}>wuhu</button>
            <h1>YOUTUBE MUSIC!</h1>
        </div>
    )
}

export default YTDashboard