//TODO: 1. Fix all check function

import React, {useEffect, useState} from 'react'
import useAuth from "./useAuth";
import SpotifyWebApi from 'spotify-web-api-node'
import Navbar from "./components/Navbar";
import { useNavigate } from "react-router-dom";
import BannerBackground from "./components/home-banner-background.png";
import Cookies from "js-cookie";

var spotifyApi = new SpotifyWebApi();

function Dashboard({code}){
    const [userID, setUserID] = useState()
    const [trackIDs, setTrackIDs] = useState([])
    const [selectedTrackInfo, setSelectedTrackInfo] = useState([]);
    const [selectedTrackId, setSelectedTrackId] = useState('');
    const [isCheckedSong, setIsCheckedSong] = useState([]);
    const [isCheckedArtist, setIsCheckedArtist] = useState([]);
    const accessToken = useAuth(code);
    const navigate = useNavigate();

    //Set the right credientials by setting access token
    useEffect(() =>{
        if (!accessToken) return
        spotifyApi.setAccessToken(accessToken)
    }, [accessToken])

    //get the account info from the access token
    useEffect(() =>{
        if (!accessToken) return
        spotifyApi.getMe().then(data=>{
            console.log(data.body)
            setUserID(data.body.id)
        }).catch((err) => {
            console.log(err)
        })
    }, [accessToken])

    //return the playlists user have
    useEffect(() =>{
        if(!userID && !accessToken) return
        spotifyApi.getUserPlaylists(userID)
            .then((data) => {
                const trackData = data.body.items;
                console.log(trackData[0]);
                const newTrackIds = trackData.map((track) => [track.id, track.name])
                console.log('Retrieved playlists', newTrackIds);
                setTrackIDs(newTrackIds)
            }).catch((err) =>{
                console.log("play list error:" + err)
            });
    }, [userID])

    const handleTrackSelect = (event) => {
        const selectedId = event.target.value;
        const selectedIndex = event.target.options.selectedIndex;
        const selectedAlbumName = event.target.options[selectedIndex].getAttribute('data-key');
        console.log(selectedId + " " + selectedAlbumName)
        if(selectedId){
            setSelectedTrackId(selectedId)
            spotifyApi.getPlaylist(selectedId).then((data => {
                setSelectedTrackInfo([data.body, selectedAlbumName])
                console.log(data.body)
            }))

        }
    }

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;

        const updatedSongs = [];
        const updatedArtists = [];

        selectedTrackInfo[0].tracks.items.forEach((songInfo) => {
            const trackName = songInfo.track.name;
            const trackArtist = songInfo.track.artists[0].name;

            // Update state based on checkbox status
            if (isChecked) {
                updatedSongs.push(trackName);
                updatedArtists.push(trackArtist);
            } else {
                // Ensure that the song and artist are removed only if they exist
                const index = isCheckedSong.indexOf(trackName);
                if (index !== -1) {
                    updatedSongs.push(trackName);
                    updatedArtists.push(trackArtist);
                }
            }
        });

        setIsCheckedSong(updatedSongs);
        setIsCheckedArtist(updatedArtists);

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
        });
    };


    const handleCheckBoxChange = (e, trackName, trackArtist) => {
        const { checked } = e.target;
        if(checked){
            setIsCheckedSong([...isCheckedSong, trackName]);
            setIsCheckedArtist([...isCheckedArtist, trackArtist]);
        }else{
            setIsCheckedSong(isCheckedSong.filter((name) => name !== trackName));
            setIsCheckedArtist(isCheckedArtist.filter((artist) => artist !== trackArtist));
        }
    };

    const selection = () => {
        return (
            <div>
                <select onChange={handleTrackSelect}>
                    <option value="">Select a track</option>
                    {trackIDs.map((id, index) => (
                        <option key={index} data-key={id[1]} value={id[0]}>
                            {id[1]}
                        </option>
                    ))}
                </select>
                {selectedTrackInfo[0] && (
                    <div>
                        <h2>{selectedTrackInfo[1]}</h2>
                        <table>
                            <tr>
                                <th></th>
                                <th>Song Name</th>
                                <th>Artist</th>
                            </tr>

                            <tr>
                                <td>
                                    <input
                                        type="checkbox"

                                        onChange={handleSelectAll}
                                    /></td>
                                <td>Select All</td>
                                <td></td>
                            </tr>
                            {selectedTrackInfo[0].tracks.items.map((songInfo, index) => (
                                <tr key={index}>
                                    <td><input type="checkbox"  onChange={(e) => handleCheckBoxChange(e, songInfo.track.name, songInfo.track.artists[0].name)}/></td>
                                    <td>{songInfo.track.name}</td>
                                    <td>{songInfo.track.artists[0].name}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                )}
            </div>
        );
    };

    function nextPage(){
        let parameter = {
            selectedTracks:isCheckedSong,
            passAccessToken: accessToken,
            selectedTrackArtist: isCheckedArtist,
            medium: "spotify"
        }
        Cookies.set("selectedTracks", JSON.stringify(isCheckedSong), {expires: 1});
        Cookies.set("passAccessToken", accessToken, {expires: 1});
        Cookies.set("selectedTrackArtist", JSON.stringify(isCheckedArtist), {expires: 1});
        Cookies.set("medium", "spotify", {expires: 1});

        navigate("/submit", {state: { selectedParameter: parameter}});

    }
    return(
        <div>
            <Navbar/>
            <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
            </div>
            {selection()}
            {selectedTrackInfo[0] &&
            <button className= "secondary-button" onClick={nextPage}>
                Next
            </button>
            }
        </div>
    )
}

export default Dashboard