import React from "react";
import "./contact.css"

const Contact = () => {
    return (
        <div className="message-page" id = "page">
            <div className="message-container">
                <div className="message">
                    <p className="message-text">.. -. / - .... . / -- --- --- -. .----. ... / -- . .-.. .-.. --- .-- / .-.. .. --. .... - --..-- / .-.. . - / --- ..- .-. / -.. .-. . .- -- ... / - .- -.- . / ..-. .-.. .. --. .... - --..-- / -- .- -.-- / -- -.-- / .... .- -. -.. / .... --- .-.. -.. / -.-- --- ..- .-. ... / ... - .. .-.. .-.. --..-- / .. / .-- .. ... .... / -.-- --- ..- / .- / --. . -. - .-.. . / -. .. --. .... -</p>
                </div>
            </div>
        </div>
    );
};

export default Contact;