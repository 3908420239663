import React, {useEffect, useState} from 'react'
import axios from "axios";

export default function useAuth(code){
    const [accessToken, setAccessToken] = useState()
    const [refreshToken, setRefreshToken] = useState()
    const [expiresIn, setExpiresIn] = useState()
    // const login_domain = 'http://localhost:3001/login';
    // const refresh_domain = 'http://localhost:3001/refresh';

    const login_domain = 'https://justmusic-production.up.railway.app/login';
    const refresh_domain = 'https://justmusic-production.up.railway.app/refresh';

    useEffect(() => {
        console.log("login useEffect")
        axios.post(login_domain, {
            code,
        }).then(res => {
            console.log(res.data)
            setAccessToken(res.data.accessToken)
            setRefreshToken(res.data.refreshToken)
            setExpiresIn(res.data.expiresIn)
            // console.log("the first refreshToken is:" + refreshToken)
            window.history.pushState({}, null, "/pageProcessor")
            }
        ).catch((error) => {
            console.log("redirecting because login credential is invalid: " + error);
            console.log("code is: " + code);
            // window.location = '/'
        })
    }, [code])

    useEffect(() => {
        console.log("refresh token useEffect")
        if(refreshToken) {
            const interval = setInterval(() => {
                axios.post(refresh_domain, {
                    refreshToken,
                }).then(res => {
                        setAccessToken(res.data.accessToken)
                        setExpiresIn(res.data.expiresIn)
                    }
                ).catch((err) => {
                    console.log("refresh token err" + err)
                    // window.location = '/'
                })
            }, (expiresIn - 60) *1000)
            return () => clearInterval(interval)
        }}, [refreshToken, expiresIn])

    return accessToken;
}