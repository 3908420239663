import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import PageProcessor from "./tool/PageProcessor";
import Home from "./components/Home"
import "./components/landingPage.css"
import Page2 from "./components/page2";
import Contact from "./nav/Contact";
import AboutMe from "../src/components/AboutMe"
import MyInfo from "../src/components/MyInfo"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

let code =  new URLSearchParams(window.location.search).get('code');
//change
function App(){
  return(
    <Router>
        <div className={"App"}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/pageProcessor"
                    element={<PageProcessor code={code} />}
                />
                <Route
                    path="/submit"
                    element={<Page2 />}
                />
                <Route
                    path="/Night"
                    element={<Contact/>}
                />
                <Route
                    path="/About"
                    element={<AboutMe/>}
                />
                <Route
                    path="/contact"
                    element={<MyInfo/>}
                />
            </Routes>
        </div>
    </Router>

  )
}

export default App