import Navbar from "./Navbar";
import BannerBackground from "./home-banner-background.png";
import React from "react";
import image1 from "./assets/login.png";
import image2 from "./assets/naming.png";
import image3 from "./assets/upload.png";
import image4 from "./assets/playlist.png";
import "./aboutme.css";

const AboutMe =() => {
    return(
        <div className="about-page">
            <Navbar/>
            <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
            </div>
            <h2>How should you use NoNo Music?</h2>

            <div className="workflow-container">
                <div className="workflow-step">
                    <img src={image1} alt="Step 1" />
                    <p>Step 1: Login with YouTube Music or Spotify</p>
                    <div className="arrow-right"></div>
                </div>
                <div className="workflow-step">
                    <img src={image2} alt="Step 2" />
                    <p>Step 2: Select the playlist you want to copy</p>
                    <div className="arrow-right"></div>
                </div>
                <div className="workflow-step">
                    <img src={image3} alt="Step 3" />
                    <p>Step 3: Select the songs in the playlist you want to copy</p>
                    <div className="arrow-right"></div>
                </div>
                <div className="workflow-step">
                    <img src={image4} alt="Step 4" />
                    <p>Step 4: Name the new playlist name and upload it to your desired platform!</p>
                </div>
            </div>
        </div>
    )

}

export default AboutMe;

