//TODO: 1. Fix all check function
import React from 'react';


function MyInfo(){

    return(
        <div className="contact-page-wrapper">
            <h1 className="primary-heading">Have Question In Mind?</h1>
            <h1 className="primary-heading">Contact Me</h1>
            <div className="contact-form-container">
                <p> siwu945@gmail.com</p>
            </div>
        </div>
    )
}

export default MyInfo;