import React from "react";
import AboutBackground from "./about-background.png";
import AboutBackgroundImage from "./img3.png";

const About = () => {
    return (
        <div className="about-section-container">
            <div className="about-background-image-container">
                <img src={AboutBackground} alt="" />
            </div>
            <div className="about-section-image-container">
                <img src={AboutBackgroundImage} alt="" />
            </div>
            <div className="about-section-text-container">
                <p className="primary-subheading">Unleash the Power of Your Playlists</p>
                <h1 className="primary-heading">
                    Music Should Not Be A Limitation
                </h1>
                <p className="primary-text">
                    NoNo Music is your one-stop solution for seamlessly transferring your music playlists from Spotify,
                    Apple Music, or any other platform to YouTube Music, or your preferred music
                    streaming service.
                </p>
                <p className="primary-text">
                    No more recreating playlists from scratch! Simply migrate your music
                    library and continue your journey uninterrupted.
                </p>
                <div className="about-buttons-container">
                    <button className="secondary-button">Learn More</button>
                </div>
            </div>
        </div>
    );
};

export default About;
