//TODO: 1. Fix all check function
import React from 'react';
import YTDashboard from "../youtubeMusic/YTDashboard";
import Dashboard from "../Dashboard";
import "../components/table.css"


function PageProcessor({code}){
    const pageToShow = () => {
        if (code.includes("4/0")){
            return <YTDashboard code={code} />
        }else{
            return <Dashboard code={code}/>
        }
    }


    return(
        <div>
            {code ? (
                pageToShow()
            ): (
                <div>
                    <p>Please log in first to view the content.</p>
                    <button>
                        <a href={"/"}>Back to Home</a>
                    </button>
                </div>
            )}
        </div>
    )
}

export default PageProcessor