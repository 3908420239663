//TODO: 1.The User can decide whether they can add songs to an existing playlist or create a new playlist
//TODO: 2. Where do you want to upload this?
//TODO: 3. Display the songs that have been uploaded
//TODO: create a cookie in

import SpotifyWebApi from 'spotify-web-api-node';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BannerBackground from "./home-banner-background.png";
import Navbar from "./Navbar";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
let code =  new URLSearchParams(window.location.search).get('code');

var spotifyApi = new SpotifyWebApi();

export default function Page2(){
    const [plName, setplName] = useState('');
    const [medium, setMedium] = useState('');
    const [submit, setSubmit] = useState(false)
    const [newPlaylistID, setNewPlaylistID] = useState('');
    const [trackToSearch, setTrackToSearch] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    //local host
    // const googleRedirect = "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&include_granted_scopes=true&response_type=code&client_id=231033912261-o0muea7k68lb3dfpe7cac5f4cbvftj9q.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsubmit";
    // const googleCreatePL = "https://localhost:3001/createYTPL"

    //nono music
    const googleRedirect = "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&include_granted_scopes=true&response_type=code&client_id=231033912261-o0muea7k68lb3dfpe7cac5f4cbvftj9q.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fwww.musicnono.com%2Fsubmit";
    const googleCreatePL = "https://justmusic-production.up.railway.app/createYTPL"

    let selectedTracks = JSON.parse(Cookies.get("selectedTracks"));
    let selectedTrackArtist = JSON.parse(Cookies.get("selectedTrackArtist"));
    let mediumPlatform = Cookies.get("medium");
    let passAccessToken = Cookies.get("passAccessToken");
    let submitMedium = Cookies.get("submitMedium");

    useEffect(() =>{
        if (!passAccessToken){
            console.log("no access token")
            return
        }
        spotifyApi.setAccessToken(passAccessToken)
    }, [passAccessToken]);


    if(submitMedium === "Youtube Music") {
        const select_platform = document.getElementsByClassName("select-platform");
        console.log(select_platform[0]);
        select_platform[0].textContent = mediumPlatform
        Cookies.remove("submitMedium");

    }

    useEffect(() =>{
        if (!plName) return
        if(submit){
            if(medium === "Spotify"){
                spotifyApi.createPlaylist(plName, { 'description': 'My description', 'public': true })
                    .then(function(data) {
                        console.log('Created playlist! ID:', data.body.id);
                        setNewPlaylistID(data.body.id);
                        setSubmit(false)
                    }, function(err) {
                        console.log('Creating Playlist failed!', err);
                    });
            }
            else if(medium === "Youtube Music"){
                axios.post(googleCreatePL, {
                    code, plName
                }).then(res =>{
                    console.log("youtube playlist created");
                })
            }

        }
    }, [submit])

    useEffect(() => {
        spotifyApi.addTracksToPlaylist(newPlaylistID, trackToSearch)
            .then(function(data) {
                console.log('Added tracks to playlist!');
            }, function(err) {
                console.log('adding tracks to playlist wrong!', err);
                console.log(submit);
            });
    }, [newPlaylistID])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const trackLst = await getSpotifyTrackIDByName(selectedTracks, selectedTrackArtist);

        for(let i = 0; i < selectedTracks.length; i ++){
            console.log(trackLst[i]);
        }
        setTrackToSearch(trackLst);
        setSubmit(true);
        setShowAlert(true);
    }

    function CustomAlert({ message, show, onClose }) {
        return (
            <Modal className={"pop-window"} show={show} onHide={onClose} centered >
                <Modal.Header className={"pop-window-header"} closeButton>
                    <Modal.Title className="ms-auto">Playlist Added</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"pop-window-body border-0"}>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer className={"border-0"}>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const getSpotifyTrackIDByName = async (songNames, songArtists) => {
        let trackLst = [];

        // Create an array of promises using map
        const promises = songNames.map((songName, index) => {
            const searchQuery = 'track:' + songName + ' artist:' + songArtists[index];
            return spotifyApi.searchTracks(searchQuery)
                .then(function (data) {
                    console.log('Found the track:', data.body.tracks.items[0].id);
                    return "spotify:track:" + data.body.tracks.items[0].id;
                })
                .catch(function (err) {
                    console.log('searching track went wrong!', err);
                    return null;
                });
        });

        // Wait for all promises to resolve using Promise.all
        trackLst = await Promise.all(promises);

        return trackLst;
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    const messageAlert = (
        <span>
            Your Playlist <span style={{ color: 'red' }}>{plName}</span> is successfully uploaded
        </span>
    );

    const login = (medium) => {
        console.log("youtube login herrrrrrrrrr" + medium +" platform: "+ mediumPlatform);
        Cookies.set("submitMedium", medium, {expires:1});
        setMedium(medium);

        if(mediumPlatform==="spotify" && medium === "Youtube Music"){
            window.location.href = googleRedirect;
        }
    }


    return(
        <div>
            <Navbar/>
            <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
            </div>
            {selectedTracks ? (
                // Render content when selectedTrack is not null or undefined
                <div className="create">
                    <h2>Selected Tracks</h2>
                    <form onSubmit={handleSubmit}>
                        <label>Enter the name for your new track</label>
                        <input
                            type="text"
                            required
                            value={plName}
                            onChange={(e) => setplName(e.target.value)}
                        />
                        <label>Where do you want to upload this? (try upload Spotify from Spotify)</label>
                        <select
                            value={medium}
                            onChange={(e) => login(e.target.value)}
                        >
                            <option className={"select-platform"} value="please select"> Please Select </option>
                            <option className={"select-platform"} value="Youtube Music"> Youtube Music</option>
                            <option className={"select-platform"} value="Spotify"> Spotify </option>
                        </select>
                        <button>Add Playlist</button>
                    </form>

                    <CustomAlert message={messageAlert} show={showAlert} onClose={handleCloseAlert}/>
                    <table>
                        <thead>
                        <tr>
                            <th>Track Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedTracks.map((track, index) => (
                            <tr key={index}>
                                <td>{track}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                // Render content when selectedTrack is null or undefined
                <div>
                    <p>Please log in first to view the content.</p>
                </div>
            )}
        </div>
    )
}