import React, {useState} from "react";
import {
    Box, Drawer, ListItem,ListItemButton, ListItemIcon, ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import List from "@mui/material/List";
import InfoIcon from "@mui/icons-material/Info";
import Divider from "@mui/material/Divider";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

const Navbar =() => {
    const [openMenu, setOpenMenu] = useState(false);
    const menuOptions = [
        {
            text:"Home",
            icon:<HomeIcon/>
        },
        {
            text:"About",
            icon:<InfoIcon/>
        },
        {
            text: "Contact",
            icon: <PhoneRoundedIcon />
        },
    ];
    return <nav>
        <div className="nav-logo-container">
            <a href={"/"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="300" height="40" viewBox="0 0 300 40">
                    <text x="10" y="30" font-size="43" fill="#ff9d0d">NoNo Music</text>
                </svg>
            </a>

            {/*<img src={brandLogo} alt={""}/>*/}
        </div>
        <div className="navbar-links-container">
            <a href={"/"}>Home</a>
            <a href={"/About"}>About</a>
            <a href={"/About"}>How?</a>
            <a href={"/contact"}>Contact</a>
            <button className="primary-button">
                <a href={"/About"}>Start Your Music Journey Now!</a>
            </button>
        </div>
        <div className="navbar-menu-container">
            <span onClick={() => setOpenMenu(true)}>
            &#9776; {/* Unicode for the three horizontal bars */}
            </span>
        </div>
        <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
            <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
            >
            <List>
                {menuOptions.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            </Box>
        </Drawer>
    </nav>
};

export default Navbar