import React from "react";
import PickMeals from "./assets/pick-meals-image.png";
import ChooseMeals from "./assets/choose-image.png";
import DeliveryMeals from "./assets/delivery-image.png";

const Work = () => {
    const workInfoData = [
        {
            image: PickMeals,
            title: "No Password Sharing",
            text: "With OAuth 2.0, you're never required to share your passwords with NoNo Music. Instead, you're directed to the platform's secure login page, where you enter your credentials."
        },
        {
            image: ChooseMeals,
            title: "Access Tokens",
            text: "After successful authentication, the platform provides NoNoMusic with a secure token, which are securely generated by the platform you're logging in with, without sharing your password."
        },
        {
            image: DeliveryMeals,
            title: "Limited Permissions",
            text: "NoNo Music requests only the minimum permissions required to provide the intended functionality, ensuring no unnecessary information being stored or access."
        },
    ];
    return (
        <div className="work-section-wrapper">
            <div className="work-section-top">
                <p className="primary-subheading">Safety</p>
                <h1 className="primary-heading">Your Information is Safe</h1>
                <p className="primary-text">
                    NoNo Music use OAuth 2.0, an industry-standard authentication protocol adopted by major platforms like Google,
                    Facebook, and Twitter. OAuth 2.0 is designed to provide a secure and
                    standardized way for users to grant access to their accounts without sharing their passwords.
                </p>
            </div>
            <div className="work-section-bottom">
                {workInfoData.map((data) => (
                    <div className="work-section-info" key={data.title}>
                        <div className="info-boxes-img-container">
                            <img src={data.image} alt="" />
                        </div>
                        <h2>{data.title}</h2>
                        <p>{data.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Work;