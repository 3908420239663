import React, { useState } from 'react';
import "./assets/CookieBanner.css";
import monster from "./assets/cookieMonster.png"
import Cookies from "js-cookie";


function CookieBanner() {
    const [isVisible, setIsVisible] = useState(true);

    if(Cookies.get("showCookie")){
        if(!JSON.parse(Cookies.get("showCookie"))){
            return null;
        }
    }
    const acceptCookies = () => {
        Cookies.set("showCookie", "false", {expires:1});
        setIsVisible(false)
    };

    if (!isVisible) {
        return null; // Banner is hidden if cookies are accepted.
    }


    return (
        <div className="cookie-banner">
            <div className="cookie-banner-content">
                <div className="left-section">
                    <img src={monster} alt="Cookie Image" />
                    <p>Without my cookies, I am just a monster...</p>
                </div>
                <p>This website uses cookies to ensure you have the best browsing experience on our website.</p>
                <button onClick={acceptCookies} className="accept-button">
                    Got It!
                </button>
            </div>
        </div>

    );
}

export default CookieBanner;
