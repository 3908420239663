import React, {useEffect, useState} from "react";
import Navbar from "./Navbar";
import BannerBackground from "../components/home-banner-background.png"
import {YTLogin} from "../youtubeMusic/YTLogin";
import About from "./About";
import Safety from "./Safety"
import Contact from "./Contact"
import CookieBanner from "./CookieBanner";

const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=95a109c8dd9f44b4b7cb3e6f60353697&response_type=code&redirect_uri=https://musicnono.com/pageProcessor&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state%20playlist-modify-public`

const redirect_url = "http://localhost:3000/pageProcessor";
// const redirect_url = "http://localhost:3000";

// const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=95a109c8dd9f44b4b7cb3e6f60353697&response_type=code&redirect_uri=${redirect_url}&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state%20playlist-modify-public`

const Home =() => {
    const [redirectURL, setRedirectURL] = useState('');
    const [googleLoginClicked, setGoogleLoginClicked] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const handleGoogleLogin = async () => {
        setGoogleLoginClicked(true);
        const result = await YTLogin();
        setRedirectURL(result)
    };

    const handleSpotifyLogin = async() =>{
        window.location.href = AUTH_URL;
    }

    useEffect(() => {
        if (initialized &&googleLoginClicked) {
            console.log(redirectURL);
            window.location.href = redirectURL;
        } else {
            setInitialized(true);
        }
    }, [redirectURL]);

    return <div className={"home-container"}>
        <Navbar/>
        <div className="home-banner-container">
            <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
            </div>
            <div className="home-text-section">
                <h1 className="primary-heading">
                    Your Favourite Music Non-stop
                </h1>
                <p className="primary-text">
                    Are you tired of being confined to a single music streaming platform, missing out on the music treasures that other
                    services have to offer? Say goodbye to limitations, and say hello to endless possibilities with NoNo Music!
                </p>
                <button className="secondary-button" onClick={handleSpotifyLogin}>
                    Login With Spotify
                </button>
                <button className="youtube-button" onClick={handleGoogleLogin}>
                    Login With Youtube (Functionality still developing){" "}
                </button>

            </div>
        </div>
        <About/>
        <Safety/>
        <Contact/>
        <CookieBanner/>
    </div>
};

export default Home